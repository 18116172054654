import React, { memo } from 'react'
import { withProps } from 'recompose'
import { I } from '../../common/globals'
import { AT_SAVED, CLOSED, SENT } from '../../common/v5/constants'
import {
  composeWithDisplayName,
  withUnmountWhenHidden
} from '../../reactcomponents/hocs'
import { Message } from './Message'

const QuestionBase = ({
  writer,
  timestamp,
  attachmentType,
  canEditQuestion,
  innerRef,
  tags,
  myErrandId,
  area,
  html,
  hid,
  onActionClick,
  from,
  breakLine,
  truncateErrandMessageByDefault,
  onSetErrandMessageTruncatePref,
  onClickAgentAssistTrigger,
  onSend,
  onSocialMediaUIAction,
  autoClassifyLang,
  ...props
}) => {
  return (
    <Message
      photo={writer.photo}
      innerRef={innerRef}
      isAgent={false}
      status={I('Sent')}
      statusId={SENT}
      truncateText
      name={props.channelId === Workflow.Errand.SERVICE_MANUAL_VOICE ? writer.name : from }
      tags={tags}
      hid={hid}
	  autoClassifyLang={autoClassifyLang}
      translate={false}
      edit={canEditQuestion}
      attachmentType={AT_SAVED}
      myErrandId={myErrandId}
      time={timestamp}
      showSize={0}
      html={html}
      onActionClick={onActionClick}
      breakLine={breakLine}
      truncateErrandMessageByDefault={truncateErrandMessageByDefault}
      onSetErrandMessageTruncatePref={onSetErrandMessageTruncatePref}
      onClickAgentAssistTrigger={onClickAgentAssistTrigger}
      onSend={onSend}
      area={area}
      onSocialMediaUIAction={onSocialMediaUIAction}
      showExactDayAndTime={props.showExactDayAndTime}
      {...props}
    />
  )
}

const withHideablePrintClone = composeWithDisplayName(
  'withHideablePrintClone',
  withUnmountWhenHidden,
  memo,
  withProps({ clone: true, print: true })
)

const Question = withHideablePrintClone(QuestionBase)

export default Question

function getErrandStatusText(actionsHistory) {
  let status = ""
  if (typeof actionsHistory !== 'undefined' && actionsHistory.length > 0) {
    let action
    for (let i = actionsHistory.length; i > 0; i--) {
      action = actionsHistory[i-1]
      if (action.notes.indexOf('answered and closed') > -1 || action.notes.indexOf('replied to in errand')>-1 || action.notes.indexOf('reviewed and closed') > -1 || action.notes.indexOf('answered in errand')>-1) {
        if (action.notes.indexOf('pending for review') > -1) {
          status = I('Answered, pending for review')
        } else {
          status = I('Answered')
        }
        break
      } else if (action.notes.indexOf('answered but not closed') > -1 || action.notes.indexOf('reviewed but not closed') > -1) {
        if (action.notes.indexOf('pending for review') > -1) {
          status = I('Partly answered, pending for review')
        } else {
          status = I('Partly answered')
        }
        break
      } else if (action.notes.indexOf('forwarded to') > -1) {
        status = I('Forwarded')
        break
      } else if (action.notes.indexOf('deleted by') > -1) {
        status = I('Deleted')
        break
      } else if (action.notes.indexOf('resent by') > -1) {
        status = I('Resent')
        break
      }
    }
  }
  return status
}

const AnswerBase = ({
    actionsHistory,
    answered,
    associatedErrands,
    attachmentType,
    edit,
    html,
    onActionClick,
    timestamp,
    writer,
    truncateErrandMessageByDefault,
    onSetErrandMessageTruncatePref,
    onClickAgentAssistTrigger,
    onSend,
    area,
    onSocialMediaUIAction,
    myErrandId,
    automatedAnswer,
    currentSuggestedAnswerUsed,
    ...props
}) => {
  let status
  let writerPhoto
  let writerName
  let statusId = -1
  if(typeof html === 'undefined') {
		  html='';
  }
  if (typeof answered === 'undefined') {
    if (html === '') {
      status = I('Unanswered')
    } else {
      status = I('Saved')
    }
  } else if (answered) {
    let isPartial = false
    if (typeof actionsHistory !== 'undefined' && actionsHistory.length > 0 &&
      actionsHistory[actionsHistory.length - 1].notes.indexOf('answered but not closed') > -1) {
      isPartial = true
    }
    if (isPartial) {
      status = I('Partly answered')
    } else {
      status = I('Closed')
      statusId = CLOSED
    }
  } else {
    status = I('Saved')
  }
  let newStatus = getErrandStatusText(actionsHistory)
  if (newStatus != "") {
    status = newStatus
  }
  if (writer) {
    writerPhoto = writer.photo
    writerName = writer.name
  } else {
    writerName = ''
  }
  return (
    <Message
      photo={writerPhoto}
      isAgent
      status={status}
      truncateText
      name={writerName}
      edit={edit}
      actionsHistory={actionsHistory}
      attachmentType={AT_SAVED}
      myErrandId={myErrandId}
      area={area}
      translate={false}
      time={timestamp}
      showSize={0}
      html={html}
      statusId={statusId}
      onActionClick={onActionClick}
      extraMeta={associatedErrands}
      truncateErrandMessageByDefault={truncateErrandMessageByDefault}
      onSetErrandMessageTruncatePref={onSetErrandMessageTruncatePref}
      onClickAgentAssistTrigger={onClickAgentAssistTrigger}
      onSend={onSend}
      onSocialMediaUIAction={onSocialMediaUIAction}
      showExactDayAndTime={props.showExactDayAndTime}
      fromAI={automatedAnswer}
      currentSuggestedAnswerUsed={currentSuggestedAnswerUsed}
      {...props}
    />
  )
}

export const Answer = withHideablePrintClone(AnswerBase)

const withoutEditPrintClone = withProps(({
  edit: false, clone: false, print: false
}))

export const ReadOnlyQuestion = withoutEditPrintClone(QuestionBase)

export const ReadOnlyAnswer = withoutEditPrintClone(AnswerBase)
